
import { defineComponent } from 'vue';
import SiteMenu from '@/views/components/menu.vue';
import { TUser, TUserValidation } from "@/types/user";


export default defineComponent({
	name: 'Login',
	comments: { SiteMenu },
	data() {
		return {
			data: {} as TUser,
			errors: {} as TUserValidation,
			error: '',
			pass1: '',
			agree: false,
			disabled: false,
			sent: false
		};
	},
	methods: {
		validate(): TUserValidation {
			const errors = {} as TUserValidation;

			/* validation */
			if (!this.data.fName?.trim()) errors.fName = 'First name cannot be empty';
			if (!this.data.lName?.trim()) errors.lName = 'Last name cannot be empty';
			if (!this.agree) errors.agree = 'Privacy statement and terms should be reviewed and agreed';
			if (!this.data.organization) errors.organization = 'Please, provide your organization name';
			if (!this.data.role) errors.role = 'Please, describe your role';

			if (!this.data.email?.trim()) {
				errors.email = 'E-mail address cannot be empty';
			} else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.data.email)) {
				errors.email = 'Please, enter a valid e-mail address';
			}

			if (!this.data.pass?.trim()) {
				errors.pass = 'Password cannot be empty';
			} else if (this.data.pass?.trim().length < 5) {
				errors.pass = 'Your password must contain at least 5 symbols';
			}

			if (!this.pass1?.trim()) {
				errors.pass1 = 'Password cannot be empty';
			} else if (this.data.pass !== this.pass1) {
				errors.pass1 = 'Verification and password should match';
			}
			return errors;
		},

		register() {
			this.error = '';
			this.errors = this.validate();

			/* if no errors - submit */
			if (!Object.keys(this.errors).length) {
				this.disabled = true;
				this.$api.users.register(this.data)
					.then((data) => {
						this.disabled = false;
						if (data !== 'ok') {
							this.error = data;
							return;
						}
						this.disabled = false;
						this.sent = true;

						// const { lName, fName, token, expiration } = data;
						//
						// const future = new Date();
						// future.setTime(expiration.getTime());
						//
						// localStorage.setItem('expiration', "" + future.getTime());
						// localStorage.setItem('fName', fName);
						// localStorage.setItem('lName', lName);
						// localStorage.setItem('token', token);
						//
						// // if (errors) {
						// // 	this.errors = data.errors;
						// // } else {
						// // this.error = 'Unable to log in...';
						// // }
						// this.$bus.$emit('logged');
						// this.$router.push({
						// 	name: 'Main'
						// });
					})
					.catch(() => {
						this.disabled = false;
						localStorage.removeItem('user');
						// this.errors = 'Backend error - unable to process request';
					});
			}
		}
	}
});
